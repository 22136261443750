import React, { useContext, useLayoutEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import Layout from "../../theme/layout"
import SEO from "../header/seo"

import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"

import { getQueryVariable } from "../../lib/utils"
import LinkWrapper from "../../lib/link-wrapper"
import EllipsisLoader from "../generic/loaders/elipsis-loader"
import CheckoutTabs from "./checkout-tabs"

const CheckoutWrapper = ({
  children,
  orderData,
  location,
  checkoutStep = "delivery",
  skipRedirect = false,
  loading = false,
}) => {
  const { loggedInState } = useContext(AuthContext)
  const { cartContents } = useContext(CartContext)

  // Check if user is not logged in, navigate to login page
  useLayoutEffect(() => {
    if (loggedInState === false && !skipRedirect) {
      navigate("/login/", {
        replace: true,
        state: {
          fromUrl: "/checkout/delivery/",
          showNotice: "Please login or register to complete your order",
        },
      })
    }
  }, [loggedInState])

  // This is body of the checkout. It is defined here because there are so many conditions to decide
  // what should be rendered and we check too many local variables to refactor into a component
  let CheckoutBody = (
    <CheckoutSplitStyling>
      <EllipsisLoader height="500px" />
    </CheckoutSplitStyling>
  )
  // Convenience variables
  const cartIsEmpty = cartContents?.length === 0
  const noOrder = !((orderData && orderData.id) || getQueryVariable("orderid"))

  // Cart ready and not loading show stuff, otherwise show loader fallback
  if (!loading) {
    if (
      (checkoutStep == "delivery" && cartIsEmpty) ||
      (checkoutStep == "payment" && cartIsEmpty && noOrder)
    ) {
      // Show empty cart message if no cart is available or no order te repay
      CheckoutBody = (
        <EmptyCartMessageStyle>
          Your cart is empty.{" "}
          <LinkWrapper to={`/shop/`}>Go shopping!</LinkWrapper>
        </EmptyCartMessageStyle>
      )
    } else if (loggedInState && children) {
      CheckoutBody = children
    }
  }

  return (
    <Layout location={location}>
      <SEO title="Checkout" location={location} />
      <StyledH1>Checkout</StyledH1>
      {checkoutStep !== "summary" ? (
        <StyledH3>Just a few more steps to go</StyledH3>
      ) : (
        <StyledH3>Order status</StyledH3>
      )}
      <CheckoutTabs checkoutStep={checkoutStep} />
      {CheckoutBody}
    </Layout>
  )
}

export default CheckoutWrapper

// ===============
//     STYLES
// ===============
const EmptyCartMessageStyle = styled.div`
  text-align: center;
  font-size: 24px;
  margin: 25px;
`

const StyledH1 = styled.h1`
  margin: 0;
`
const StyledH3 = styled.h3`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 1.777rem;
`
// These styles are exported and reusable for components on the checkout page
export const CheckoutSplitStyling = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  & > div {
    box-shadow: 0px 0px 25px ${({ theme }) => theme.colors.lightgrey3};
    padding: 30px 40px;
  }
  & > div:first-child {
    width: 66.1%;
  }
  & > div:last-child {
    width: 32.2%;
  }
  & > div:only-child {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    & > div {
      padding: 25px;
    }
    & > div:first-child {
      width: 100%;
      margin-bottom: 20px;
    }
    & > div:last-child {
      width: 100%;
    }
  }
`

export const RightSideSection = styled.div``

export const CheckoutOptionsStyles = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    position: relative;
    width: 100%;
    margin: auto 10px;
    padding: 1.1rem 0;

    &:only-child {
      cursor: default;
    }
  }
  p {
    width: 100%;
  }

  svg {
    margin-left: 10px;
    opacity: 0;
    position: absolute;
    right: 25px;
    top: calc(50% - 7px);

    &.selected {
      opacity: 1;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    margin: 15px 0px;
    button {
      width: 100%;
      margin: 10px 0px;
      padding: 1rem;
    }
  }
`
