import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"

import BlurLoader from "../generic/loaders/blur-loader"
import { CheckoutOptionsStyles } from "./checkout-wrapper"
import CartContext from "../../context/cart/cart-context"
import CheckSquare from "../../images/icons/check-square"
import UncheckedSquare from "../../images/icons/unchecked-square"
import CheckoutContext from "../../context/checkout/checkout-context"

const ShippingOptions = () => {
  const { cartReady, cartMeta, selectShippingMethod, branches } =
    useContext(CartContext)
  const {
    selectedBranch,
    setSelectedBranch,
    availableBranches,
    availableProvinces,
    selectedProvince,
    setSelectedProvince,
  } = useContext(CheckoutContext)

  // Are there multiple shipping methods to choose from?
  const multipleShippingMethods =
    cartMeta?.available_shipping_methods?.length > 1

  useEffect(() => {
    if (
      availableBranches.length > 0 &&
      selectedBranch >= availableBranches.length
    ) {
      setSelectedBranch(0)
    }
  }, [availableBranches])

  // Show shipping options only if there are ones to choose from
  if (multipleShippingMethods) {
    return (
      <BlurLoader loading={!cartReady} message="Updating">
        <h3>Select Shipping Method</h3>
        <p>
          Current shipping method:{" "}
          {cartReady ? (
            <BoldSpan>{cartMeta?.selected_shipping_method?.label}</BoldSpan>
          ) : (
            <BoldSpan>...</BoldSpan>
          )}
        </p>
        <CheckoutOptionsStyles>
          {cartMeta?.available_shipping_methods?.map((method) => {
            return (
              <button
                key={method.key}
                className={
                  cartMeta?.selected_shipping_method?.key === method.key
                    ? "button-active"
                    : ""
                }
                onClick={() => selectShippingMethod(method.key)}
              >
                {method.label}
              </button>
            )
          })}
        </CheckoutOptionsStyles>
        <p style={{ marginBottom: "30px" }}>
          {cartMeta?.selected_shipping_method?.key?.includes(
            "local_pickup"
          ) && (
            <div>
              <h3>Select province</h3>
              <StyledSelect
                onChange={(event) => setSelectedProvince(event.target.value)}
                defaultValue={selectedProvince}
              >
                {availableProvinces.map((province) => (
                  <option value={province}>{province}</option>
                ))}
              </StyledSelect>
              <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
                Select Collection Address
              </h3>
              {availableBranches.map((branch, index) => (
                <BranchDetails
                  className={index === selectedBranch ? "selected" : ""}
                  onClick={() => {
                    setSelectedBranch(index)
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: branch?.contact_details?.name,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: branch?.address?.street_address,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: branch?.contact_details?.contact_email,
                    }}
                  />
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${branch?.contact_details?.telephones[0]?.telephone_number}`,
                      }}
                    />
                  </div>
                  <CheckSquare className="check-square"></CheckSquare>
                  <UncheckedSquare className="unchecked-square"></UncheckedSquare>
                </BranchDetails>
              ))}
            </div>
          )}
        </p>
      </BlurLoader>
    )
  }

  // Don't render anything if there aren't shipping options
  return null
}

export default ShippingOptions

// ===============
//     STYLES
// ===============
const BoldSpan = styled.span`
  font-weight: bold;
`

const BranchDetails = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lightgrey2};
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  position: relative;
  div {
    transition: color 0.1s ease-out;
    max-width: calc(100% - 50px);
  }

  .check-square,
  .unchecked-square {
    top: calc(50% - 15px);
    right: 20px;
    height: 30px;
    width: 30px;
    position: absolute;
    opacity: 0;

    rect {
      stroke: ${({ theme }) => theme.colors.lightgrey4};
    }
  }

  .unchecked-square {
    opacity: 1;
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary};

    div {
      color: white;
    }

    .check-square,
    .unchecked-square {
      opacity: 1;
    }

    .unchecked-square {
      opacity: 0;
    }
  }
`

const StyledSelect = styled.select`
  font: normal normal normal 16px/30px Poppins;
  width: 100%;
  padding: 1rem 20px;
  option {
    padding: 1rem 0;
  }
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  outline: none;
  border-right-width: 20px;
  background-color: ${({ theme }) => theme.colors.lightgrey2};
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    width: 300px;
  }
  margin: 10px;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 300px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin: 10px 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
`
