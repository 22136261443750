import React, { useContext, useState } from "react"
import styled from "styled-components"

import CartContext from "../../context/cart/cart-context"

const ContinueButton = ({
  callback,
  text = "Continue",
  disabled = false,
  className = "",
}) => {
  const { cartReady } = useContext(CartContext)
  return (
    <StyledButton
      onClick={callback}
      type="button"
      disabled={!cartReady || disabled}
      className={className}
    >
      {text}
    </StyledButton>
  )
}

export default ContinueButton

// ===============
//     STYLES
// ===============
const StyledButton = styled.button`
  margin: auto;
`
