import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import CheckSquare from "../../images/icons/check-square"

const CheckoutTabs = ({ checkoutStep = "delivery" }) => {
  return (
    <TabContainer>
      <Tab
        active={!checkoutStep || checkoutStep === "delivery"}
        done={checkoutStep !== "delivery"}
        onClick={() =>
          checkoutStep === "summary" ? null : navigate("/checkout/delivery/")
        }
        clickable={checkoutStep === "payment"}
      >
        {!checkoutStep || checkoutStep === "delivery" ? null : <CheckSquare />}
        Billing Details
      </Tab>
      <Tab
        active={checkoutStep === "payment"}
        done={checkoutStep === "summary"}
      >
        {checkoutStep === "summary" ? <CheckSquare /> : null}
        Payment
      </Tab>
      <Tab active={checkoutStep === "summary"} done={false}>
        Order Summary
      </Tab>
    </TabContainer>
  )
}

export default CheckoutTabs

// ===============
//     STYLES
// ===============

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Tab = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 32.2%;
  text-align: center;
  font-size: 1.333rem;
  color: white;
  height: 80px;
  &:first-of-type {
    cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  }
  background: ${({ theme, active, done }) => {
    if (active) return theme.colors.brandHover
    if (done) return theme.colors.brandPrimary
    return theme.colors.grey
  }};
  svg {
    margin-right: 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    max-width: 100%;
    display: ${({ active, done }) => {
      if (active) return "flex"
      if (done) return "flex"
      return "none"
    }};
    margin-bottom: ${({ active, done }) => {
      if (active) return "0"
      if (done) return "20px"
      return "0"
    }};
  }
`

// :Not currently used, but kept for reuse
const handleScrollIntoView = (elementId) => {
  try {
    const isBrowser = () => typeof window !== "undefined"
    if (isBrowser() && elementId) {
      setTimeout(() => {
        try {
          const element = document.getElementById(elementId)
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
            })
          }
        } catch (e) {}
      }, 100)
    }
  } catch (e) {}
}
